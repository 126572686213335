import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import NumberFinder from '../components/NumberFinder'
import useAuth from "../hooks/userAuth";
const IndexPage = () => {
  const { isLogin } = useAuth()
  return <Layout afterSearch={<></>} >
    <NumberFinder

      renderComponent={(e) => <>
        <div className="text-center">
          <strong>Please whatapps us to get this number.</strong>
        </div></>}
    />
  </Layout >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
